import React from "react";
import { Link } from "gatsby"
import { createUseStyles } from "../../styling";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";

const useStyles = createUseStyles({
    sideItem: {
        marginBottom: "0px",
        transition: "color 0.3s ease-in-out",
        '& :hover': {
            transition: "color 0.3s ease-in-out",
            color: "black",
            backgroundColor: "#F9FAFB",
            borderRadius: "4px",
        },
    },
    item: {
        display: "flex",
        alignItems: "center",
        color: "#3D495B",
        '& p': {
            marginTop: "0px",
            marginBottom: "0px",
            fontFamily: "inter",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "18px",
            paddingTop: "5px",
            paddingBottom: "5px",
            marginLeft: "20px",
            paddingRight: "5px",
			transition: "color 0.1s ease-in-out",
        }
    },
    dropdownIcon: {
        position: "absolute",
        left: "3px",
    },
    itemHasChild: {
        paddingBottom: "0px",
    },
    dropdown: {
        marginLeft: "10px",
        borderLeft: "2px solid #DDE2E9",
        display: "block",
        '& $item': {
            paddingBottom: "0px",
        },
        '& :hover': {
            transition: "color 0.3s ease-in-out",
            color: "black",
            backgroundColor: "#F9FAFB",
            borderRadius: "4px",
        },
    },
    dropdownAdjust: {
        marginLeft: "10px",
    },
    dropdownActiveAdjust: {
        marginBottom: "10px",
        marginTop: "5px",
    },
    activeIcon: {
        transform: "rotate(90deg)",       

    },
    activeLink: {
        color: "#3B82F6",
        borderColor: "#3B82F6",
        '& $item p': {
            color: "#3B82F6",
        },
        '& $dropdownIcon': {
            color: "#3B82F6",
        }

    },
    miniHeader: {
        color: "#252F3F",
        fontFamily: "inter",
        fontWeight: 600,
        fontSize: "14px",
        textTransform: "uppercase",
        paddingBottom: "10px",
        paddingTop: "20px",
        lineHeight: "20px",
        marginTop: "0px",
        marginBottom: "0px",
        paddingLeft: "20px",
        paddingRight: "5px",
    },
    '@media screen and (max-width: 769px)': {
        activeLink: {
            color: "#3B82F6",
        },
        dropdown: {
            border: 0,
        },
        item: {
            color: "#FFFFFF",
            '& p': {
                color: "white",
            }
        },
        miniHeader: {
            color: "#8592A5",
        }
    }
})

const SideItem = ({
    to,
    label,
    className,
    hasChildren,
    url,
    miniHeader,
}: {
    to: string;
    label: string;
    className?: string,
    hasChildren: INavlinks[],
    url: string,
    miniHeader?: string,
}) => {
    const classes = useStyles();
    return (
        <>
            {miniHeader &&
                <p className={classes.miniHeader}>{miniHeader}</p>
            }
            <Link
                to={to}
                className={className ? className : classes.sideItem}
                activeClassName={classes.activeLink}
            >
                <li className={`${classes.item} ${hasChildren.length > 0 && url.includes(to) && classes.itemHasChild}`}>
                    {
                        hasChildren.length > 0 &&
                        <span className={classes.dropdownIcon}><FontAwesomeIcon icon={faCaretRight} className={`${url.includes(to) && classes.activeIcon}`} /></span>
                    } 
                    <p>{label}</p>
                </li>
            </Link>
            {
                url.includes(to) &&
                <div className={`${classes.dropdownAdjust} ${hasChildren.length !== 0 && classes.dropdownActiveAdjust}`}>
                    {
                        hasChildren?.map(link => (
                            <div key={link.frontmatter.title}>
                                <SideItem
                                    to={link.frontmatter.path}
                                    className={classes.dropdown}
                                    label={link.frontmatter.title}
                                    hasChildren={link.children as INavlinks[]}
                                    miniHeader={link.frontmatter.miniHeader}
                                    url={url} />
                            </div>
                        ))
                    }
                </div>
            }
        </>
    )
};

export default SideItem;