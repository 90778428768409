import React, { useState, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import CommandIcon from "../../images/command.svg";
import { graphql, useStaticQuery, Link } from "gatsby";
import {
  Index,
  Document,
  EnrichedDocumentSearchResultSetUnitResultUnit,
} from "flexsearch";

const MAX_RESULTS = 10;
const MAX_HEADING_DEPTH = 3;

type Headings = {
  value: string;
  depth: number;
};

type DocumentNode = {
  id: string;
  node: {
    frontmatter: {
      miniHeader: string;
      path: string;
      title: string;
    };
    internal: {
      content: string;
    };
    headerContent?: string;
  };
  content?: string;
  header?: string;
};

type Node = {
  frontmatter: {
    miniHeader: string;
    path: string;
    title: string;
  };
  id: string;
  headings: Headings[];
  internal: {
    content: string;
  };
  headerContent?: string | undefined;
};

type Data = {
  allMdx: {
    nodes: Node[];
  };
};

type Result = {
  frontmatter: {
    path: string;
    title: string;
    miniHeader: string;
  };
  internal: {
    content: string;
  };
  headerContent?: string | undefined;
};

const useStyles = createUseStyles({
  container: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    marginLeft: "20px",
  },
  icon: {
    position: "absolute",
    height: "100%",
    paddingLeft: "15px",
    color: "#8592A5",
  },
  input: {
    height: "39px",
    border: "1px solid #DDE2E9",
    borderRadius: "4px",
    width: "100%",
    paddingLeft: "42px",
    "&::placeholder": {
      color: "#8592A5",
      fontFamily: "inter",
    },
  },
  results: {
    top: "40px",
    position: "absolute",
    listStyle: "none",
    padding: 4,
    backgroundColor: "white",
    border: "1px solid grey",
    width: "100%",
    "& a": {
      cursor: "pointer",
    },
  },
  searchInput: {
    display: "flex",
    flexGrow: 1,
    marginRight: "20px",
    position: "relative",
    "& input": {
      borderRadius: 8,
      border: "1px solid #DDE2E9",
      outline: "none",
      padding: "11px 34px",
      width: "100%",
      color: "#8592A5",
    },
    "& input:placeholder": {
      color: "#8592A5",
    },
    "& input:focus": {
      border: "1px solid #3B82F6!important",
    },
    "& input:focus-visible": {
      border: "1px solid #3B82F6!important",
    },
    "& ul": {
      position: "absolute",
      top: "50px",
      padding: "9px",
      margin: "0px",
      backgroundColor: "white",
      borderRadius: 8,
      border: "1px solid #DDE2E9",
      boxShadow: "0px 4px 20px 4px rgba(15, 23, 42, 0.12)",
    },
  },
  searchInputValue: {
    display: "flex",
    flexGrow: 1,
  },
  inputContainer: {
    display: "flex",
    flexGrow: "1",
    position: "relative",
    "& > svg": {
      height: "100%",
      position: "absolute",
      color: "#B5C0CF",
      fontSize: "14px",
    },
  },
  iconOn: {
    opacity: 1,
  },
  iconOff: {
    opacity: 0,
  },
  searchIcon: {
    left: "15px",
  },
  commandIconContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "calc(100% - 40px)",
    height: "100%",
  },
  commandIconWrapper: {
    borderRadius: 6,
    border: "1px solid #B5C0CF",
    padding: "2px 4px",
    "& span": {
      paddingLeft: "3px",
      fontFamily: "inter",
      color: "#B5C0CF",
    },
  },
  commandIcon: {
    "& svg": {
      height: "100%",
      fontSize: "14px",
    },
  },
  searchResults: {
    width: "calc(100% - 20px)",
    "& a": {
      paddingBottom: "10px",
    },
    "& a:first-of-type": {
      paddingTop: "10px",
    },
  },
  emptyResults: {
    color: "#B5C0CF",
  },
});

const LinkedOption = ({ value, label }: { value: string; label: string }) => {
  return (
    <Link to={value} data-dropdown="search-link">
      <span data-dropdown="search-label">{label}</span>
    </Link>
  );
};

const SearchBar = () => {
  const data: Data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          id
          frontmatter {
            path
            title
            miniHeader
          }
          headings {
            value
            depth
          }
          internal {
            content
          }
        }
      }
    }
  `);

  const indexDocument = new Document<DocumentNode, true>({
    document: {
      id: "id",
      store: true,
      index: [
        {
          field: "header",
          preset: "match",
          tokenize: "forward",
        },
        {
          field: "content",
          preset: "match",
          tokenize: "forward",
        },
      ],
    },
  });
  // kept the title as its super simple search for now
  const indexTitle = new Index({
    preset: "match",
    tokenize: "forward",
  });

  data.allMdx.nodes.forEach((node) => {
    // add title
    indexTitle.add(node.id, node.frontmatter.title.toLowerCase());

    // add headers
    node.headings
      .filter((h) => h.depth <= MAX_HEADING_DEPTH)
      .forEach((heading, index) => {
        const headingId = "-h#" + index.toString();
        const header = heading.value;
        const path = header
          ? node.frontmatter.path +
            "/#" +
            header.toLowerCase().replaceAll(" ", "-")
          : node.frontmatter.path;
        const title = header
          ? node.frontmatter.title + " > " + header
          : node.frontmatter.title;
        indexDocument.add({
          id: node.id + headingId,
          header: heading.value.toLowerCase(),
          node: {
            ...node,
            headerContent: heading.value,
            frontmatter: {
              ...node.frontmatter,
              path,
              title,
            },
          },
        });
      });

    // add content
    const content = node.internal.content.toLowerCase();
    indexDocument.add({
      id: node.id,
      content: content,
      node: {
        ...node,
      },
    });
  });

  const [query, setQuery] = useState<string | null>(null);
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const filterData = (results: string[]) => {
    return data.allMdx.nodes.filter(({ id }) => results.includes(id));
  };

  const createBodyData = (
    data: EnrichedDocumentSearchResultSetUnitResultUnit<DocumentNode>[],
    input: string = "",
    previousResults: Result[] = []
  ) => {
    return data
      .map((res) => {
        const content = res.doc.node.internal.content;
        const inputIndex = content.indexOf(input);
        let anchor = "";
        let path = res.doc.node.frontmatter.path;
        let title = res.doc.node.frontmatter.title;
        if (!previousResults.some((prev) => prev.frontmatter.path === path)) {
          const closestAnchorIndex = content
            .substring(0, inputIndex + input.length)
            .lastIndexOf("# ");
          if (closestAnchorIndex !== -1) {
            const anchorCut = content.substring(
              closestAnchorIndex,
              content.length
            );
            anchor = anchorCut.substring(2, anchorCut.indexOf("\n")); // start after '# ' which is 2 char
            path += "/#" + anchor.replaceAll(" ", "-");
            title += " > " + anchor;
          }
          return {
            ...res.doc.node,
            frontmatter: {
              ...res.doc.node.frontmatter,
              path: path,
              title: title,
            },
          };
        }
      })
      .filter((res) => !!res);
  };

  const loadOptions = (inputValue: string | null) => {
    if (!inputValue) {
      return [];
    }
    let results: Result[] = [];
    const titleIndexSearch = indexTitle.search(inputValue.toLowerCase(), {
      limit: MAX_RESULTS,
    });
    results = [...filterData(titleIndexSearch as string[])];
    if (results.length < MAX_RESULTS) {
      // odd typing but in order to return enriched search type we have to include the limit twice
      const docSearch = indexDocument.search(
        inputValue.toLowerCase(),
        MAX_RESULTS - results.length,
        {
          enrich: true,
          index: ["header"],
          limit: MAX_RESULTS - results.length,
        }
      );
      if (docSearch.length) {
        results = [
          ...results,
          ...docSearch[0].result.map((res) => res.doc.node),
        ];
      }
    }
    if (results.length < MAX_RESULTS) {
      const docSearch = indexDocument.search(
        inputValue.toLowerCase(),
        MAX_RESULTS - results.length,
        {
          enrich: true,
          index: ["content"],
          limit: MAX_RESULTS - results.length,
        }
      );
      if (docSearch.length) {
        const body = createBodyData(
          docSearch[0].result,
          inputValue.toLowerCase(),
          results
        );
        if (body.every((h) => h !== undefined)) {
          results = [...results, ...body] as Result[];
        }
      }
    }
    return results.map(
      (node: { frontmatter: { path: string; title: string } }) => ({
        value: node.frontmatter.path,
        label: node.frontmatter.title,
      })
    );
  };

  useEffect(() => {
    const keyboardHandler = (e: KeyboardEvent) => {
      if (searchRef.current) {
        if (((e.metaKey || e.ctrlKey) && e.key === "k") || e.key === "/") {
          e.preventDefault();
          searchRef.current.focus();
        }
        if (e.key === "Escape") {
          searchRef.current.blur();
          onBlur();
        }
      }
    };
    const mousedownHandler = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement) {
        const target = e.target.getAttribute("data-dropdown");
        if (!target) {
          onBlur();
        }
      }
    };
    globalThis.addEventListener("keydown", keyboardHandler);
    globalThis.addEventListener("mouseup", mousedownHandler);
    return () => (
      globalThis.removeEventListener("keydown", keyboardHandler),
      globalThis.removeEventListener("mouseup", mousedownHandler)
    );
  }, [searchRef]);

  const classes = useStyles();
  const options = loadOptions(query);
  return (
    <div className={classes.container}>
      <div className={classes.searchInput}>
        <div className={classes.inputContainer}>
          <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
          <input
            ref={(el) => (searchRef.current = el)}
            placeholder="Search docs"
            value={query ?? ""}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={onFocus}
            data-dropdown="search-input"
          />
          <div className={classes.commandIconContainer}>
            <div className={classes.commandIconWrapper}>
              <img src={CommandIcon} className={classes.commandIcon} />
              <span>k</span>
            </div>
          </div>
        </div>
        {query && focused && (
          <ul className={classes.searchResults} data-dropdown="search-results">
            {options.length ? (
              loadOptions(query).map((option) => (
                <LinkedOption label={option.label} value={option.value} />
              ))
            ) : (
              <span className={classes.emptyResults}>No results found</span>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
