interface IDataObj { 
    children: {}, 
    frontmatter : { type: string, title: string, path: string},
    name: string,
    relativeDirectory: string,
}

export const createDirectoryTree = (data:NodeData[]):NavData=> {
    // length of index in front of folder including underscore
    const indexLength = 4; 
    let tree: Record<string, IDataObj> = {};

    const createNode = (data:NodeData) => {
        let splitpath: string[] = data.relativePath.split('/');
        let ptr: Record<string, IDataObj> = tree;
        for ( let i = 0; i < splitpath.length - 1; i++) {
            if (data.childMdx === null) continue;
            const node = { 
                name: splitpath[i].substr(indexLength),
                frontmatter: data.childMdx.frontmatter,
                relativeDirectory: "/" + data.relativeDirectory,
            };
            
            if (splitpath[i] === "index.md" || splitpath[i] === "index.mdx") {
                break;
            } 

            ptr[splitpath[i]] = ptr[splitpath[i]] || node;
            ptr[splitpath[i]].children = ptr[splitpath[i]].children || {};
            ptr = ptr[splitpath[i]].children; // this can be an empty object
        }
    }

    // copped from stackoverflow
    const objectToArr = (node: Record<string, IDataObj> | IDataObj) => {
        if (node.frontmatter) {
            node = node as IDataObj;
            node.children = Object.values(node.children)
            if (Array.isArray(node.children)) {
                node.children.forEach(objectToArr);
            }
        } else {
            node = node as Record<string, IDataObj>;
            Object.keys(node).map((key) => {
                const nodeValue = node[key as keyof typeof node];
                if (typeof nodeValue === 'object' && nodeValue !== null) {
                    if ('children' in nodeValue) {
                        objectToArr(nodeValue);    
                    }
                }
            })
        }
    }
    
    // find largest depth possible
    const longestPath = data.sort((a:NodeData, b:NodeData) => {
            return b.relativePath?.split('/').length - a.relativePath?.split('/').length;
        }
    )[0];
    
    // arrange directories by depth
    const sortedDepth = data.sort((a:NodeData, b:NodeData) => {
        return a.relativePath?.split('/').length - b.relativePath?.split('/').length;
    });

    // sort directories index + create nodes
    // we start 2 because the small dir is always going to be a lengh of 2 (dirName, index.mdx)
    for (let i = 2; i < longestPath.relativePath.split('/').length + 1; i++) {
        const toSortByInded: NodeData[] = sortedDepth.filter((path:NodeData) => 
            path.relativePath.split('/').length === i
        );
        const sortedByIndex: NodeData[] = toSortByInded.sort((a,b)=> {
            let aName, bName = null;
            if (i === 2) {
                aName = a.relativeDirectory;
                bName = b.relativeDirectory;
                return parseInt(a.relativeDirectory.substr(0, indexLength - 1)) - parseInt(b.relativeDirectory.substr(0, indexLength - 1));
            } else {
                return parseInt(a.relativeDirectory.substr(
                        a.relativeDirectory.lastIndexOf('/') + 1, indexLength - 1
                    )) - parseInt(b.relativeDirectory.substr(
                        b.relativeDirectory.lastIndexOf('/') + 1, indexLength - 1
                    ));
            }
        })
        sortedByIndex.map(createNode);
    }

    objectToArr(tree)
    return Object.values(tree);
}