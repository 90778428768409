import React, { FC } from "react"
import { ThemeProvider, createUseStyles } from "react-jss";
import { theme } from "../../styling";
import { graphql, useStaticQuery } from "gatsby";
import { createDirectoryTree } from "../util/createDirectoryTree";
import Nav from "../nav";
import SideNav from "../sideNav";
const useStyles = createUseStyles({
  container: {
    height: "100vh",
  },
  mainWrapper: {
    marginTop: "63px",
    width: "100%",
    maxWidth: "1400px",
    margin: "0 auto",
    paddingLeft:"26px",
    paddingRight: "26px",
    height: "max-content",
  },
  contentContainer: {
    paddingTop: "30px",
    paddingLeft: "222px",
    paddingRight: "237px",
  },
  contentContainerRoot: {
    paddingTop: "30px",
    paddingLeft: "244px",
    paddingRight: "0px",
  },
  desktopSideNav: {

  },
  adjustScrollable: {
    paddingLeft:'15px',
  },
  '@media screen and (max-width: 1251px)': {
    mainWrapper: {
      width: "auto",
      paddingRight: "0px",
    },
    contentContainer: {
      paddingRight: "0px",
    },
    adjustScrollable: {
      paddingLeft: "0px",
    }
  },
  '@media screen and (max-width: 769px)': { 
    mainWrapper: {
      paddingLeft: "0px",
    },
    desktopSideNav: {
      display: "none",
    },
    contentContainer: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    contentContainerRoot: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  }
})

interface MappedLinks {
  mappedLinks: {
    nodes: NodeData[]
  }
}

const Layout: FC<{ location: Location }> = ({ location, children }) => {
  const classes = useStyles();
  const isRootPath = location.pathname === `/`;
  const pathSplit = location.pathname.split("/");
  const data: MappedLinks = useStaticQuery(graphql`
    query pageList {
      mappedLinks: allFile(filter: {sourceInstanceName: {eq: "doc"}}) {
        nodes {
          relativePath
          relativeDirectory
          name
          childMdx {
            frontmatter {
              title
              path
              description
              miniHeader
            }
          }
        }
      }
    }
  `)

  const mappedLinks: NodeData[] = data?.mappedLinks?.nodes;
  const navDirectory = createDirectoryTree(mappedLinks); //todo fixup the data return
  // this return should have a loading state or some error that devs will most likley only see.
  // this technically only works if there is atleast 1 doc markdown. created the length check so it just doesn't crash otherwise
  return (
    <>
      {  
        navDirectory.length && 
          <ThemeProvider theme={theme}>
            <div data-is-root-path={isRootPath} className={classes.container}>
              <Nav 
                rootPath={navDirectory[0].frontmatter.path}
                navLinks={navDirectory} 
                url={location.pathname}
              />
              <main className={classes.mainWrapper}>
                <div className={classes.desktopSideNav}>
                  <SideNav sideLinks={navDirectory} url={location.pathname}/>
                </div>
                <div className={`${
                  (isRootPath || (pathSplit.length === 2 || (pathSplit.length > 2 && pathSplit[2] === "")) 
                    ? classes.contentContainerRoot 
                    : classes.contentContainer)
                  }`}>
                  {children}
                </div>
              </main>
            </div>
          </ThemeProvider>
      }
    </>
  )
};

export default Layout;
