import React from "react";
import { Link } from "gatsby";
import { createUseStyles, useTheme } from "../../styling";

import Account from "../account";
import logo from "../../images/fuzzbuzz_logo_white.svg";
import SideNav from "../sideNav";

const useStyles = createUseStyles({
  logoContainer: {
    position: "relative",
    top: "22px",
    left: "20px",
    width: "max-content",
  },
  slideoutLogo: {
    height: ({ theme }) => theme.logo.height,
  },
  openMenu: {
    left: "0vw",
    visibility: "visible",
  },
  closedMenu: {
    left: "100vw",
    visibility: "hidden",
  },
  menu: {
    position: "fixed",
    top: "0px",
    height: "100vh",
    width: "100vw",
    background: "rgb(24, 33, 46, 0.95)",
    mixBlendMode: "normal",
    zIndex: 3,
    backdropFilter: "blur(10px)",
    transition: "ease-in-out 0.2s",
    display: "flex",
    flexDirection: "column",
    '& hr': {
      width: "100%",
      marginTop: "0px",
      marginBottom: "0px",
      display: "block",
      height: "1px",
      border: 0,
      backgroundColor: "#3D495B",
    }
  },
  slideOutContent: {
    marginTop: "50px",
  },
  itemsList: {
    display: "flex",
    flexDirection: "column",
    marginRight: "30px",
    listStyle: "none",
    '& a': {
      textDecoration: "none",
      color: "#ffffff",
      fontFamily: "inter",
      fontSize: "16px",
    },
    '& a li': {
      marginBottom: "0px",
    },
  },
  navItem: {
    marginLeft: "40px",
    textDecoration: "none",
    "& li": {
      marginBottom: 0,
      color: "#FFF",
      fontSize: "16px",
      fontFamily: "inter",
      fontWeight: 500,
      lineHeight: "19px",
    },
  },
  icon: {
    backgroundColor: "#4C63B6",
    color: "white",
    fontSize: "15px",
  },
  mainNavButtons: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "40px",
    '& button': {
      cursor: "pointer",
      backgroundColor: "#18212E",
      borderRadius: "6px",
      color: "#fff",
      border: 0,
      boxShadow: "none",
      fontFamily: "inter",
      fontSize: "14px",
      fontWeight: 600,
      padding: "10px 12px",
      textTransform: "uppercase",
    },
    '& :nth-child(2)': {
      marginLeft: "6px",
      marginRight: "6px",
    }
  },
  active: {
    '& button': {
      color: "#fff",
    },
  }
});

const SlideOut = ({isOpen, navLinks, url} : {isOpen: boolean, navLinks: NavData, url: string}) => {
  const classes = useStyles();
  return (
    <>
      <div className={`${isOpen ? classes.openMenu : classes.closedMenu} ${classes.menu}`}>
        <Link to={"/"} className={classes.logoContainer}>
          <img src={logo} className={classes.slideoutLogo} />
        </Link>
        <div className={classes.slideOutContent}>
          {/* <div className={classes.mainNavButtons}>
            {
              navLinks.map(item => (
                <Link
                  to={"/" + item.frontmatter.path}
                  activeClassName={classes.active}
                  partiallyActive={true}
                  key={item.relativeDirectory}>
                  <button>{item.frontmatter.title}</button>
                </Link>
              ))
            }
          </div> */}
          <ul className={classes.itemsList}>
            <SideNav sideLinks={navLinks} url={url} />
          </ul>
        </div>
        <hr />
        <Account />
      </div>
    </>
  )
}

export default SlideOut;