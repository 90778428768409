import React, { useState } from "react";
import { Link } from "gatsby";
import { css, createUseStyles, useTheme } from "../../styling";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import SlideOut from "../slideOut";

import logo from "../../images/fuzzbuzz_logo.svg";

const useStyles = createUseStyles({
  mobile: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  logoWrapper: {
    position: "relative",
    zIndex: 1,
    marginRight: "auto",
    marginLeft: "0px",
  },
  logo: {
    width: "auto",
    marginRight: "auto",
    height: ({ theme }) => theme.logo.height,
  },
  mobileNav: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginRight: "0px",
    zIndex: 4,
    width: "22px",
  },
  iconOn: {
    opacity: 1,
  },
  iconOff: {
    opacity: 0,
  },
  hamburgerIcon: {
    color: "#252F3F",
    fontSize: "22px",
    transition: "ease-in-out 0.2s",
  },
  closeIcon: {
    color: "#B5C0CF",
    fontSize: "28px",
    transition: "ease-in-out 0.2s",
    position: "relative",
    right: "17px",
  },
  '@media screen and (min-width: 770px)': {
    mobile: {
      display: "none",
    },
  },
});

const Mobile = ({navLinks, url} : {navLinks: NavData, url:string}) => {
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles();

  return (
    <div className={classes.mobile}>
      <Link to="/" className={css(classes.logoWrapper)}>
        <img src={logo} className={css(classes.logo)} />
      </Link>
      <div className={css(classes.mobileNav)} onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={faBars} className={`${isOpen ? classes.iconOff : classes.iconOn} ${classes.hamburgerIcon}`} />
        <FontAwesomeIcon icon={faTimes} className={`${isOpen ? classes.iconOn : classes.iconOff} ${classes.closeIcon}`} />
      </div>
      <SlideOut navLinks={navLinks} isOpen={isOpen} url={url} />
    </div>
  )
}

export default Mobile;