import React from "react";
import { Link } from "gatsby";
import { createUseStyles, useTheme } from "../../styling";

import NavItem from "../navItem";
import Account from "../account";
import SearchBar from "../searchBar";
import logo from "../../images/fuzzbuzz_logo.svg";

const useStyles = createUseStyles({
  desktop: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    zIndex: 9,
    top: "0px",
    left: "0px",
    width: "100vw",
    backgroundColor: "white",
    borderBottom: "1px solid #DDE2E9",
  },
  nav: {
    display: "flex",
    alignItems: "center",
    height: ({ theme }) => theme.nav.height,
    width: "100%",
    maxWidth: "1200px",
    margin: "0px auto",
    "& a": {
      display: "flex",
    },
  },
  navList: {
    display: "flex",
    listStyle: "none",
    margin: 0,
    "& a": {
      textDecoration: "none",
      color: "#18212E",
      marginRight: "5px",
      fontFamily: "inter",
      fontSize: "14px",
      fontWeight: 500,
      cursor: "pointer",
      padding: "12px 14px",
      borderRadius: "8px",
    },
  },
  logo: {
    width: "auto",
    height: ({ theme }) => theme.logo.height,
  },
  "@media screen and (max-width: 769px)": {
    desktop: {
      display: "none",
    },
  },
});

const Desktop = ({
  rootPath,
  navLinks,
  url,
}: {
  rootPath: string;
  navLinks: NavData;
  url: string;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.desktop}>
      <Link to="/">
        <img src={logo} className={classes.logo} />
      </Link>
      {/* <ul className={classes.navList}>
            { 
              navLinks.map(item => (
                <NavItem rootPath={rootPath} url={url} to={item.frontmatter.path} label={item.frontmatter.title} key={item.relativeDirectory}/>
              ))
            }
          </ul> */}
      <SearchBar />
      <Account />
    </div>
  );
};

export default Desktop;
