import React from "react";
import { createUseStyles, useTheme } from "../../styling";

import Desktop from "./desktop";
import Mobile from "./mobile";

const useStyles = createUseStyles({
  header: {
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    zIndex: 9,
    top: "0px",
    left: "0px",
	width: "100vw",
    backgroundColor: "white",
    borderBottom: "1px solid #DDE2E9",
  },
  nav: {
    display: "flex",
    alignItems: "center",
    height: ({ theme }) => theme.nav.height,
    width: "100%",
    maxWidth: "1400px",
    margin: "0px auto",
    marginLeft:"26px",
    marginRight: "26px",
    '& a': {
      display:"flex",
    }
  },
  navList: {
    display: "flex",
    listStyle : "none",
    margin: 0,
    '& a': {
      textDecoration: "none",
      color: "#18212E",
      marginRight: "5px",
      fontFamily: "inter",
      fontSize: "14px",
      fontWeight: 500,
      cursor: "pointer",
      padding:"9px 14px",
      borderRadius: "4px",
    },
  },
  logo: {
    width: "auto",
    height: ({ theme }) => theme.logo.height,
  },
  adjustScrollable: {
    paddingLeft: "8px",
  },
  '@media screen and (max-width: 1201px)': {
    adjustScrollable: {
      paddingLeft: "0px",
    }
  },
  '@media screen and (max-width: 850px)': { 
    nav: {
      width: "100%",
      margin: "0px",
      marginLeft: "20px",
      marginRight: "20px",
    },
    logoWrapper: {
      marginLeft: "0px",
    },
  }
});

const Nav = ({rootPath, navLinks, url} : {rootPath: string, navLinks: NavData, url:string}) => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <nav className={classes.nav}> 
        <Mobile 
          navLinks={navLinks}
          url={url}

        />
        <Desktop 
          rootPath={rootPath}
          navLinks={navLinks}
          url={url}
        />
      </nav>
    </header>
  )
}

export default Nav;
