import React from "react";
import { createUseStyles } from "react-jss";

import discordIcon from "../../images/discord-link.svg";


const useStyles = createUseStyles({
    accountSection: {
        display: "flex",
        width: "max-content",
        marginLeft: "auto",
        alignItems: "center",
        "& a": {
            color: "#3B82F6",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 500,
            marginRight: "10px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            "& img": {
                paddingRight: "5px",
            },
        }
    },
    signUp: {
        marginLeft: "10px",
        backgroundColor: "#3B82F6",
        color: "white",
        border: "none",
        borderRadius: 8,
		padding: "12px 13px",
        fontSize: "14px",
        fontFamily: "inter",
        fontWeight: "400",
        cursor: "pointer",
    },
    signIn: {
        marginLeft: "10px",
        backgroundColor: "transparent",
        color: "#3B82F6",
        border: "none",
        fontSize: "14px",
        fontFamily: "inter",
        fontWeight: "400",
        cursor: "pointer",
    },
    '@media screen and (max-width: 769px)': {
        accountSection: {
            flexDirection: "column",
            width: "100%",
            marginLeft: "0px",
            marginTop: "15px",
            '& button': {
                cursor: "pointer",
                width: "236px",

                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "17px",
                height: "40px",
                borderRadius: 8,
                '& span': {
                    width: "100%",
                }
            }
        },
        signIn: {
            backgroundColor: "white",
        }

    }
});

// TODO: Finish this, tmp placeholder
const Account = () => {
    const classes = useStyles();
    return (
        <div className={classes.accountSection}>
            <a href="https://discord.fuzzbuzz.io" target="_blank"><img src={discordIcon} alt="discord-icon"></img>Community Discord </a>
            <a href="https://fuzzbuzz.typeform.com/to/ieAmr75K" target="_blank" rel="noreferrer">
				<button className={classes.signUp}>Get Early Access</button>
			</a>
        </div>
    )
};

export default Account;
